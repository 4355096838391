<template>
  <div class="plan-detail-container">
    <div class="plans-block" v-if="planDetailsContent && planDetailsContent.length">
      <div
        v-for="(content, key) in planDetailsContent"
        :key="key"
        class="plan-detail-block"
      >
        <planDetails
          :planDetailsContent="content"
          :plansSubscribed="plansSubscribed"
          :planActivated="planActivated"
          :cancelPlans=" key==0 && content.planstatus!== 'CANCEL' ? true:false "
          :fromPage="fromPage"
          :trialPlan="trialPlan"
          :expiryStatus="expiryStatus"
        />
      </div>
    </div>
    <div class="row content-row" v-else>
        <div class="no-data">{{noData}}</div>
    </div>
    <app-loader class="loader" v-show="showLoading"></app-loader>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import {currencyPass} from '@/mixins/dateSupport';

export default {
  props: ["fromPage"],
  data() {
    return {
      planDetailsContent: undefined,
      noData: "",
      plansSubscribed: false,
      subscribedPlanid: "",
      planActivated: false,
      trialPlan: false,
      subscribedPlanStatus: null,
      subscribedExpiryDate: null,
      showLoading: false,
      expiryStatus: true,
      flagAutologin: true
    };
  },
  watch: {
    plansSubscribed() {}
  },
  computed: {
    ...mapGetters(["subscriberId",'country'])
  },
  methods: {
    ...mapActions(["actGetPlans", "actGetPlansList"]),
    fetchPlans() {
      let data = {};
      data.subscriberid = this.subscriberId;
      this.showLoading = true;
      this.actGetPlans(data)
        .then(response => {
          if (!response.error) {
            this.plansSubscribed = true;
            this.planDetailsContent = [];
            // this.planDetailsContent.push(response);
            this.subscribedPlanid = response.planid;
            this.subscribedPlanStatus = response.planstatus;
            this.subscribedExpiryDate = response.expirydate;
            if (response.planstatus === "TRIAL") {
              this.trialPlan = true;
            } else {
              this.trialPlan = false;
            }
            if (
              response.planstatus === "ACTIVE" ||
              response.planstatus === "CANCEL"
            ) {
              this.planActivated = true;
            } else {
              this.planActivated = false;
            }

            if (response.planstatus === "EXPIRED") {
              this.expiredStatus = true;
              console.log("entered true");
            } else {
              console.log("entered false");
              this.expiryStatus = false;
            }

            this.listUserPlans();
          } else if (response.error === 9104) {
            console.log("entered here")
            this.plansSubscribed = false;
            this.expiryStatus = false;
            this.listUserPlans();
          }
        })
        .catch(error => {
          this.showLoading = false;
          console.log("error ", error);
          if (error.status === 401) {
            // this.unauthorisedHandler();

            if (this.flagAutologin) {
              this.commonAutoLogin(this.fetchPlans);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    listUserPlans() {
      this.actGetPlansList()
        .then(response => {
          this.showLoading = false;
          if (response.error) {
            this.noData = response.reason;
            return;
          } else if (response.data) {
            if (this.plansSubscribed) {
              let subscribedPlanDetails;
              response.data.forEach(element => {
                // if (element.userplanid !== this.subscribedPlanid) {
                if (element.userplanid === this.subscribedPlanid) {
                  element.planstatus = this.subscribedPlanStatus;
                  element.expirydate = this.subscribedExpiryDate;
                  subscribedPlanDetails = element;
                }
                if (element.userplanid !== this.subscribedPlanid) {
                  this.planDetailsContent.push(element);
                }
                // }
              });

              this.planDetailsContent.unshift(subscribedPlanDetails);
            } else {
              this.planDetailsContent = response.data;
            }
            
            this.planDetailsContent=this.planDetailsContent.filter(plan=>{
              
              return currencyPass(plan.currency,this.country);
    
            })
            if(!this.planDetailsContent.length){
                this.noData='No plans available at your location.';
                this.$emit('noplans');
            }
          }
        })
        .catch(error => {
          this.noData = error;
          this.showLoading = false;
          console.log("error ", error);
          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.listUserPlans);
              this.flagAutologin = false;
              return;
            }
          }
        });
    }
  },

  created() {
    this.fetchPlans();
    // EventBus.$on("changedStatus", result => {
    //   if (result) {
    //     // this.fetchPlans();
    //   }
    // });
    EventBus.$on("planPurchasedSuccess", result => {
      this.fetchPlans();
    });
  },
  components: {
    planDetails: () =>
      import(
        /* webpackChunkName = "plansContentChild" */ "@/components/Profile/plansContentChild.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/mediaQueries";
@import "~sass/modules/fonts";
@import "~sass/modules/variables";
.plan-detail-container {
  margin: 0 !important;
  width:100%;
  font-family: $font-family;
}
.plans-block{
  display:grid;
  grid-template-columns: repeat(3,minmax(0,1fr));
  grid-gap:20px;
  .plan-detail-block{
    position:relative;
    height:100%;
  }
}
.no-data{
  font-family: $font-family;
  font-weight: $font-weight-medium;
  color: $font-clr-gray;
  text-align:center;
  font-size: 1rem;
  line-height: 32px;
}

@include breakpoint(max1280){
  .plans-block{
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
}
@include breakpoint(max600){
  .plans-block{
    grid-template-columns: repeat(1,minmax(0,1fr));
  }
}
</style>